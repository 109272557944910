import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo/seo"
import { StaticImage } from 'gatsby-plugin-image'

const StoriesPage = () => (
  <>
    <SEO title="heisentech | Web & App Design" description="Our portfolio of · iOS Development · Android Development · Cloud Solutions · Business Website · Digital Transformation · MVP" />
    <section className="section hero">
      <div className="container big">
        <div className="text col">
          <h3 className="title" title="Our stories">Our stories</h3>
          <p className="desc">
            People say products and services evolve with time. We think the companies evolve first. Rate of evolution always depend upon the passion and energy that is put into creating a product or a service. <br /><br />We are listing below few of our success stories which showcase how we and our clients have evolved and responded to changing market dynamics and demands.
          </p>
          <Link to="#pep" className="btn-link">Read all</Link>
        </div>
        <div className="col img-wrapper">
          <StaticImage
            src="./../images/stories-hero.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            placeholder="none"
            alt="Our stories"
          />
        </div>
      </div>
    </section>
    <section className="section story" id="pep">
      <div className="container big">
        <div className="logo">
          <StaticImage
            src="./../images/clients/pep-logo.png"
            height={24}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            placeholder="none"
            alt="PEP Logo"
          />
        </div>
        <h3 className="name">Photography gets <br />it's own community.</h3>
        <p className="desc small">
          getpepped.com started as a community portal for photographers in India. A digital canvas where photographers can meet to hire other photographers specialising in some different niche. Pep was also the booking portal for the largest photography summit in Asia for years 2017, 2018 and 2019. All the booking, registration &amp; scheduling was done through the portal.<br />
          Then it evolved into a booking portal for workshops conducted throughout India by world famous photographers. Then Covid happened. During these trying times, everything moved virtually. Pep pivoted to the creators marketplace for content. Where they can build and sell their courses online. <br /><br />
          Since its inceptions, more than 10,000 transactions have been processed and more than 100K mins of videos have been watched. This is a perfect example of user feedback driven development where the product evolves based on how well the product is received by the market. We are glad to say, it is still in development.
        </p>
        <div className="row">
          <div className="tools col">
            <div className="tool">twilio</div>
            <div className="tool">vimeo</div>
            <div className="tool">mailgun</div>
            <div className="tool">angular</div>
            <div className="tool">nebular</div>
            <div className="tool">firebase</div>
            <div className="tool">unsplash</div>
            <div className="tool">razorpay</div>
            <div className="tool">analytics</div>
            <div className="tool">cloudinary</div>
            <div className="tool">cloud functions</div>
          </div>
          <div className="screenshot col">
            <StaticImage
              src="./../images/screenshots/pep-screenshot.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="PEP"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section story" id="ulwe-directory">
      <div className="container big">
        <div className="logo">
          <StaticImage
            src="./../images/clients/us-logo.png"
            width={40}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            placeholder="none"
            alt="Ulwe Services"
          />
        </div>
        <h3 className="name">Have a business in Ulwe? <br />Must register</h3>
        <p className="desc small">
          An ambitious directory app which dreams to be one step above the market leaders like just dial, indiamart and google business. This app was built ground up taking into consideration the needs of rural businesses where foot falls are low and most advertisements are through the word of mouth. Along with an easy to understand interface and elegant UI, this app is enabling the businesses to reach a wider set of audience in Ulwe.
        </p>
        <div className="row">
          <div className="tools col">
            <div className="tool">ionic</div>
            <div className="tool">firebase</div>
            <div className="tool">cloud functions</div>
            <div className="tool">angular</div>
            <div className="tool">cordova</div>
            <div className="tool">razorpay</div>
            <div className="tool">google maps</div>
            <div className="tool">algolia</div>
            <div className="tool">analytics</div>
          </div>
          <div className="screenshot col">
            <StaticImage
              src="./../images/screenshots/us-screenshot.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Ulwe Services"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section story" id="shubhvivah">
      <div className="container big">
        <div className="logo">
          <StaticImage
            src="./../images/clients/shubhvivah-logo.png"
            width={40}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            placeholder="none"
            alt="Shubhvivah logo"
          />
        </div>
        <h3 className="name">Finding a spouse <br />becomes accessible to all</h3>
        <p className="desc small">
          Arranged marriage is a common practise in India where the parent searches for a suitable spouse for their child after the marriageable age. So our target audience were elderly people who were finding eligible matches for their children. <br />
          We had multiple challenges. For starters, there is very low digital literacy amongst elderly people in rural India. Also, putting a profile on the internet still has a stigma associated with it. Thirdly, most marriages happen within the community. Fourthly, it was possible that a person of marriageable age may want to make a profile himself instead of letting his parents do the searching for him. <br /><br />
          Hence, it was imperative that the app catered to younger audiences as well. To solve these issues we developed a family of Matrimony apps where each app represented the community it intended to serve. This individual app was managed completely by an app admin appointed from within the Community. We ensured the app to be so intuitive that it could be adopted by even the elderly people. And we also ensured that it looked so modern that it remained relevant to the younger audience.<br /><br />
          Along with this, lowering the apprehensions associated with sharing personal information on the app without fearing its misuse was our crowning achievement. With more than 20,000 users and growing, it is a dynamic achievement for us. Did we mention that all this was built and scaled with such a lean footprint that nph provides this service at one sixth of the subscription charged by the competition.
        </p>
        <div className="row">
          <div className="tools col">
            <div className="tool">ionic</div>
            <div className="tool">capacitor</div>
            <div className="tool">firebase</div>
            <div className="tool">cloud functions</div>
            <div className="tool">angular</div>
            <div className="tool">razorpay</div>
            <div className="tool">vision api</div>
          </div>
          <div className="screenshot col">
            <StaticImage
              src="./../images/screenshots/shubhvivah-screenshot.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Shubhvivah"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section story" id="cricstrat">
      <div className="container big">
        <div className="logo">
          <StaticImage
            src="./../images/clients/cricstrat-logo.png"
            width={40}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            placeholder="none"
            alt="Cricstrat logo"
          />
        </div>
        <h3 className="name">Our 20-20 match:<br /> Fantasy cricket</h3>
        <p className="desc small">
          Since Cricket is no less than a religion in India, vision for this app was to replicate the success of NFL fantasy leagues in the US with a Cricket fantasy league in India. Fantasy cricket was drafted as a portal where cricket enthusiasts can build a team with strategic understanding of the world of cricket. We were provided with data like player characteristics, scoring system as well as many key protocols that would result in a “real world” like Cricket experience for the users. Also, this MVP was required to be able to draft a team, score the match etc.  <br />Not to brag, but we accomplished all of it in the mere 2 weeks that we were allotted.<br /><br />
          We believe that the final product will be different than the one we provided. But they were able to test the waters with their MVP. Important parameters like traction from the target audience, user bias towards ticket sizes, platform preferences etc were gathered to make an informed decision on the future direction of the product.
        </p>
        <div className="row">
          <div className="tools col">
            <div className="tool">cloud tasks</div>
            <div className="tool">firebase</div>
            <div className="tool">cloud functions</div>
            <div className="tool">angular</div>
            <div className="tool">ionic</div>
            <div className="tool">cricapi</div>
            <div className="tool">rxjs</div>
          </div>
          <div className="screenshot col">
            <StaticImage
              src="./../images/screenshots/cs-screenshot.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Cricstrat"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section others">
      <div className="container">
        <h3 className="title">and many more ...</h3>
        <div className="logos">
          <a className="logo" href="http://angle-engineers.com/" target="_blank" rel="noreferrer">
            <StaticImage
              src="./../images/clients/angle-engineers-logo.png"
              quality={95}
              width={75}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Esolarrs"
              className="img"
            />
            <div className="name" >Angle Engineers</div>
          </a>
          <a className="logo" href="https://abhijitbhaduri.com" target="_blank" rel="noreferrer">
            <StaticImage
              src="./../images/clients/abhijitbhaduri-logo.png"
              quality={95}
              width={75}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Abhijit Bhaduri"
              className="img"
            />
            <div className="name" >Abhijit Bhaduri & Associates</div>
          </a>
          <a className="logo" href="https://romatecsans.com" target="_blank" rel="noreferrer">
            <StaticImage
              src="./../images/clients/romatecsans-logo.png"
              quality={95}
              width={75}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Romatecsans"
              className="img"
            />
            <div className="name">Romatecsans</div>
          </a>
          <a className="logo" href="https://play.google.com/store/apps/details?id=com.kasarbusiness.app" target="_blank" rel="noreferrer">
            <StaticImage
              src="./../images/clients/kasarbusiness-logo.png"
              quality={95}
              width={75}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Kasar business"
              className="img"
            />
            <div className="name" >Kasar Business</div>
          </a>
          <a className="logo" href="https://play.google.com/store/apps/details?id=com.healthsecretnew.app" target="_blank" rel="noreferrer">
            <StaticImage
              src="./../images/clients/healthsecrets-logo.png"
              quality={95}
              width={75}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Health Secrets"
              className="img"
            />
            <div className="name" >Health Secrets</div>
          </a>
          <a className="logo" href="https://play.google.com/store/apps/details?id=com.triggers.coach" target="_blank" rel="noreferrer">
            <StaticImage
              src="./../images/clients/triggerscoach-logo.png"
              quality={95}
              width={75}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Triggers Coach"
              className="img"
            />
            <div className="name" >Triggers Coach</div>
          </a>
          <a className="logo" href="http://esolarrs.com/" target="_blank" rel="noreferrer">
            <StaticImage
              src="./../images/clients/esolarrs-logo.png"
              quality={95}
              width={75}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Esolarrs"
              className="img"
            />
            <div className="name" >Esolarrs</div>
          </a>
        </div>
      </div>
    </section>
  </>
)

export default StoriesPage
